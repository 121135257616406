<template>
  <div>
    <v-row v-if="false">
      <v-col sm="2" class="hidden-xs px-0">
        <p class="pb-0 mb-0 titleFondos">Fuente de Financiamiento</p>
        <p class="pb-0 mb-0 titleFondos">Línea de trabajo</p>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="10"
        lg="10"
        class="text-center"
        align-self="center"
      >
        <v-tabs
          v-model="tabs"
          hide-slider
          background-color="bgMinsal"
          show-arrows
        >
          <v-tab
            @click="filterInsumosBy(fondo)"
            v-for="(fondo, index) in fondos"
            :key="index"
            class="secondary--text"
          >
            <div class="d-flex flex-column">
              <p class="pb-0 mb-0">{{ fondo.fondo }}</p>
              <p class="pb-0 mb-0">{{ fondo.linea }}</p>
            </div>
          </v-tab>
        </v-tabs>
        <v-tabs-items style="width: 100%" v-model="tabs">
          <v-tab-item v-for="(fondo, index) in fondos" :key="index">
            <v-row align="center">
              <v-col cols="12" sm="4">
                <v-card color="bgMinsal" elevation="0" class="mx-2">
                  <v-card-title class="justify-center py-0 mt-5 mb-0">
                    <p class="text-h4 mb-3">
                      $
                      {{
                        Intl.NumberFormat("en-US", {
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(fondo.monto_disponible)
                      }}
                    </p>
                  </v-card-title>
                  <v-card-subtitle class="justify-center my-0 py-0">
                    <p class="my-0 py-0">{{ fondo.fondo }} disponible</p>
                  </v-card-subtitle>
                  <v-progress-linear
                    color="green accent-3"
                    rounded
                    value="100"
                    class="my-0 py-0"
                  />
                </v-card>
              </v-col>
              <v-col cols="12" sm="4">
                <v-card color="bgMinsal" elevation="0" class="mx-2">
                  <v-card-title class="justify-center py-0 mt-5 mb-0">
                    <p class="text-h4 mb-3">
                      $
                      {{
                        Intl.NumberFormat("en-US", {
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(fondo.fondos_necesarios)
                      }}
                    </p>
                  </v-card-title>
                  <v-card-subtitle class="justify-center my-0 py-0">
                    <p class="my-0 py-0">Fondos necesarios para solicitud</p>
                  </v-card-subtitle>
                  <v-progress-linear
                    color="yellow lighten-1"
                    rounded
                    value="100"
                    class="my-0 py-0"
                  />
                </v-card>
              </v-col>
              <v-col cols="12" sm="4">
                <v-card color="bgMinsal" elevation="0" class="mx-2">
                  <v-card-title class="justify-center py-0 mt-5 mb-0">
                    <p class="text-h4 mb-3">
                      $
                      {{
                        Intl.NumberFormat("en-US", {
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(fondo.saldo_fondo)
                      }}
                    </p>
                  </v-card-title>
                  <v-card-subtitle class="justify-center my-0 py-0">
                    <p class="my-0 py-0">{{ fondo.fondo }}</p>
                  </v-card-subtitle>
                  <v-progress-linear
                    color="green accent-3"
                    rounded
                    value="100"
                    class="my-0 py-0"
                  />
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-row v-if="fondosUnidad?.monto_disponible">
      <v-col cols="12" sm="4">
        <v-card color="bgMinsal" elevation="0" class="mx-2">
          <v-card-title class="justify-center py-0 mt-5 mb-0">
            <p class="text-h4 mb-3">
              $
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(fondosUnidad?.monto_disponible)
              }}
            </p>
          </v-card-title>
          <v-card-subtitle class="justify-center my-0 py-0">
            <p class="my-0 py-0 text-center">Fondos disponible</p>
          </v-card-subtitle>
          <v-progress-linear
            color="green accent-3"
            rounded
            value="100"
            class="my-0 py-0"
          />
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card color="bgMinsal" elevation="0" class="mx-2">
          <v-card-title class="justify-center py-0 mt-5 mb-0">
            <p class="text-h4 mb-3">
              $
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(fondosUnidad?.monto_necesario)
              }}
            </p>
          </v-card-title>
          <v-card-subtitle class="justify-center my-0 py-0">
            <p class="my-0 py-0 text-center">Fondos necesarios</p>
          </v-card-subtitle>
          <v-progress-linear
            color="yellow lighten-1"
            rounded
            value="100"
            class="my-0 py-0"
          />
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card color="bgMinsal" elevation="0" class="mx-2">
          <v-card-title class="justify-center py-0 mt-5 mb-0">
            <p class="text-h4 mb-3">
              $
              {{
                Intl.NumberFormat("en-US", {
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(fondosUnidad?.restante)
              }}
            </p>
          </v-card-title>
          <v-card-subtitle class="justify-center my-0 py-0">
            <p class="my-0 py-0 text-center">Fondo restante</p>
          </v-card-subtitle>
          <v-progress-linear
            color="green accent-3"
            rounded
            value="100"
            class="my-0 py-0"
          />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="6"
        sm="4"
        md="4"
        lg="4"
        v-if="!handleInsumosUFI && handleAgregarEnviarInsumo"
      >
        <v-btn class="secondary mt-10" dark small @click="agregarInsumo()">
          Agregar insumo
        </v-btn>
      </v-col>

      <v-col
        cols="10"
        xs="8"
        sm="8"
        md="8"
        lg="8"
        class="d-flex justify-end"
        v-if="!handleInsumosUFI && handleAgregarEnviarInsumo && $route.params.tipo == 1"
      >
        <v-btn outlined color="green" class="mt-10" @click="DescargarArchivo()"
          >Descargar CSV</v-btn
        >
        <v-btn outlined color="green" class="mt-10 ml-5" @click="openModal()"
          >Cargar CSV</v-btn
        >
      </v-col>
      <v-dialog v-model="dialogCSV" width="800">
        <v-card>
          <v-card-title class="text-h5 darkBlue lighten-2">
            Cargar insumos por csv
          </v-card-title>

          <v-card-actions>
            <v-file-input
              outlined
              prepend-icon=""
              placeholder="Adjuntar archivo"
              prepend-inner-icon="mdi-paperclip"
              accept=".csv"
              v-model="archivoCsv"
            >
            </v-file-input>
          </v-card-actions>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogCSV = false">
              Cancelar
            </v-btn>

            <v-btn class="secondary" dark @click="CargarArchivo()">
              Cargar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row class="mt-10 overflow-x-auto overflow-y-hidden ">
      <v-col cols="12" class="py-0 mx-0 px-0">
        <p class="px-0">Listado de insumos</p>
      </v-col>

      <template v-if="handleInsumosUFI">
        <InsumosAccordionComponent />
      </template>
      <template v-else>
        <InsumosTableComponent
          :headers="headers"
          v-on:insumosObservados="handleInsumosObservados($event)"
          @emitUpdateProv="handlerEmitProv"
        />
      </template>
    </v-row>
    <v-row>
      <v-btn
        color="secondary"
        class="mt-10 text-capitalize button-extra"
        outlined
        to="/proceso-solicitud-compra/list-solicitudes"
      >
        Volver
      </v-btn>
      <v-btn
        v-if="mostrarBTNEnviar && !insumosObservadosFlag"
        class="secondary mt-10 mx-4"
        dark
        @click="modalEnviar = true"
      >
        Enviar solicitud
      </v-btn>
    </v-row>
    <dialogoConfirmacion
      :show="modalEnviar"
      title="¿Desea enviar la solicitud a aprobación?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Aceptar"
      @close="modalEnviar = false"
      @confirm="enviarSolicitud()"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import dialogoConfirmacion from "./ConfirmationDialogComponent.vue";
import InsumosTableComponent from "./InsumosTableComponent.vue";
import InsumosAccordionComponent from "./InsumosAccordionComponent.vue";
import excel4node from "excel4node";

export default {
  name: "InsumoComponent",
  components: {
    dialogoConfirmacion,
    InsumosTableComponent,
    InsumosAccordionComponent,
  },
  props: {
    headers: { type: Array, default: () => [] },
  },
  data() {
    return {
      dialogCSV: false,
      archivoCsv: null,
      proveedores: [],
      modalEliminar: false,
      id: null,
      modalEnviar: false,
      tabs: null,
      fondosUnidad: [],
      insumosObservadosFlag: false,
      estadoSolicitudFlag: false,
      cotizaciones: [],
    };
  },
  methods: {
    ...mapActions("agregarInsumo", ["getInsumosList", "getFondosAsignados"]),
    ...mapActions("catalogo", ["obtenerProducto"]),
    ...mapMutations("agregarInsumo", [
      "setFondoFilters",
      "setInsumosList",
      "setIdSolicitud",
      "setFondosAsignados",
    ]),
    handlerEmitProv() {
      this.$emit("updateListProv");
    },
    filterInsumosBy(fondo) {
      this.setFondoFilters(fondo.id_fnto_linea_unidad);
      this.getInsumosList(this.id_solicitud);
    },
    agregarInsumo() {
      if (this.$route.params.tipo == 1 || this.$route.params.tipo == 3) {
        this.$router.push({
          name: "agregar-insumo-solicitud-compra",
          params: {
            idSolicitud: this.$route.params.id_solicitud,
            tipo: this.$route.params.tipo,
          },
        });
      }
      if (this.$route.params.tipo == 2) {
        this.$router.push("/proceso-solicitud-compra/catalogo-de-compras");
      }
    },
    async getCotizaciones() {
      const cotizaciones =
        await this.services.SolicitudCompra.getSolicitudCotizaciones(
          this.id_solicitud
        );
      this.cotizaciones = cotizaciones.data;
    },
    async enviarSolicitud() {
      await this.getCotizaciones();
      setTimeout(async () => {
        if(this.$route.params.tipo == 1){
          if (this.cotizaciones.length > 0) {
          const { status } =
            await this.services.SolicitudCompra.updateSolicitudEstado(
              this.id_solicitud,
              {
                id_estado_solicitud: 1,
                id_solicitud_paso: 2,
                id_unidad: this.selectedUnidad.id,
                id_paso_actual: this.pasoSolicitud.id,
              }
            );
          if (status == 200) {
            this.temporalAlert({
              show: true,
              message: "La solicitud ha sido enviada",
              type: "success",
            });
            this.$router.push("/proceso-solicitud-compra/list-solicitudes");
          }
        } else {
          this.temporalAlert({
            show: true,
            message: "La solicitud debe tener al menos un precio de mercado",
            type: "error",
          });
        }
        }else{
          const { status } =
            await this.services.SolicitudCompra.updateSolicitudEstado(
              this.id_solicitud,
              {
                id_estado_solicitud: 1,
                id_solicitud_paso: 2,
                id_unidad: this.selectedUnidad.id,
                id_paso_actual: this.pasoSolicitud.id,
              }
            );
          if (status == 200) {
            this.temporalAlert({
              show: true,
              message: "La solicitud ha sido enviada",
              type: "success",
            });
            this.$router.push("/proceso-solicitud-compra/list-solicitudes");
          }
        }
        
      }, 1000);
    },
    async getFondos() {
      if (this.insumosList[0]) {
        const fondos = await this.services.SolicitudCompra.getSolicitudesFondos(
          {
            id_solicitud: this.id_solicitud,
            id_unidad: this.insumosList[0]?.id_unidad,
          }
        );

        if (fondos.status == 201) {
          this.fondosUnidad = fondos.data;
        }
      }
    },
    handleInsumosObservados(observaciones) {
      if (observaciones > 0) {
        this.insumosObservadosFlag = true;
      } else {
        this.insumosObservadosFlag = false;
      }
    },

    openModal() {
      this.dialogCSV = true;
    },

    async CargarArchivo() {
      const data = new FormData();
      data.append("archivo", this.archivoCsv);
      data.append("id_unidad", this.selectedUnidad.id);
      if (this.archivoCsv != null) {
        const response = await this.services.SolicitudCompra.PostCargarCsv(
          this.$route.params.id_solicitud,
          data
        );
        if (response.status === 200) {
          this.temporalAlert({
            show: true,
            message: "Csv cargado exitosamente",
            type: "success",
          });
          this.archivoCsv == null;
          this.dialogCSV = false;
          const SiguientePaso = setTimeout(()=>{
            location.reload(this.getInsumosList());
          },2500);
        }
      }else{
        this.temporalAlert({
          show: true,
          message: "Debe cargar un documento con formato CSV para continuar.",
          type: "error",
        });
      } 
    },

    async DescargarArchivo() {
      const workbook = new excel4node.Workbook();
      const sheet = workbook.addWorksheet("Datos");
      const columns = [
        { columna: 1, nombre: "codigo" },
        { columna: 2, nombre: "especificacion" },
        { columna: 3, nombre: "cantidad" },
        { columna: 4, nombre: "precio_unitario" },
        { columna: 5, nombre: "cifrado_presupuestario" },
      ];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < columns.length; i++) {
        sheet.cell(1, columns[i].columna).string(columns[i].nombre);
      }

      const data = await workbook.writeToBuffer();
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "plantilla-insumos.xlsx");
      document.body.appendChild(link);
      link.click();
    },
  },
  computed: {
    ...mapState("agregarInsumo", ["id_solicitud", "fondos", "insumosList"]),
    ...mapState(["selectedUnidad", "cargos"]),
    ...mapState("comprasModule", ["pasoSolicitud", "pasosSolicitudes"]),
    ...mapState(["userInfo", 'sockets']),
    cargoSeleccionado() {
      return this.selectedUnidad.cargo[0].id;
    },
    mostrarBTNEnviar() {
      const cargosPermitidos = [9, 3, 5];
      const pasosPermitidos = [1, 8, 12, 13];
      return (
        cargosPermitidos.includes(this.cargoSeleccionado) &&
        this.insumosList.length > 0 &&
        pasosPermitidos.includes(this.pasoSolicitud?.id)
      );
    },
    handleInsumosUFI() {
      return (
        (this.cargoSeleccionado == this.cargos.jefe_ufi ||
          this.cargoSeleccionado == this.cargos.tecnico_ufi) &&
        (this.pasoSolicitud?.id == this.pasosSolicitudes.aprobadaUnidad ||
          this.pasoSolicitud?.id == this.pasosSolicitudes.elaborada)
      );
    },
    handleAgregarEnviarInsumo() {
      if (
        this.selectedUnidad?.cargo[0]?.id === this.cargos.tecnico_unidad ||
        this.selectedUnidad?.cargo[0]?.id === this.cargos.tecnico_ufi ||
        this.selectedUnidad?.cargo[0]?.id === this.cargos.tecnico_uaci
      ) {
        return this.pasoSolicitud?.id == 1 ||
          this.pasoSolicitud?.id == 8 ||
          this.pasoSolicitud?.id == 12 ||
          this.pasoSolicitud?.id == 13 ||
          this.pasoSolicitud == null
          ? true
          : false;
      } else {
        return false;
      }
    },
  },

  async created() {
    this.getFondos();
    this.getCotizaciones();
  },
  watch: {
    insumosList() {
      this.getFondos();
    },
  },
  beforeDestroy() {
    this.setInsumosList([]);
    this.setFondosAsignados([]);
    this.setFondoFilters(null);
  },
};
</script>
<style lang="scss" scoped>
.hidden-xs {
  display: none;
}

@media (min-width: 500px) {
  .hidden-xs {
    display: block;
  }
}

.titleFondos {
  font-size: 14px !important;
}

.v-tab {
  text-transform: none !important;
  font-size: 14px !important;
  text-align: start !important;
}

.v-tabs:not(.v-tabs--vertical) .v-tab.v-tab--active {
  border: 1px solid #404a7a !important;
  border-radius: 4px !important;
}

.theme--light.v-tabs-items {
  padding-top: 10px !important;
  background: #f4f7fd !important;
}
</style>
