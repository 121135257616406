<template>
  <v-dialog
    persistent
    v-model="abrirDialogo"
    max-width="600"
    @click:outside="$emit('cerrar'), cerrar()"
  >
    <v-card style="height: 450px auto" class="pa-7">
      <v-card-title>
        <p class="text-h6 secondary--text">
          Selección de administrador de contrato
        </p>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="9">
            <v-text-field
              outlined
              label="Correo Electrónico *"
              v-model="email"
              class="mx-2"
              :clearable="!bloquearCampoEmail"
              :readonly="bloquearCampoEmail"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-btn
              block
              v-show="btnVerificar"
              @click="verificarEmail"
              color="success"
              >verificar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="verificarAdmin">
        <v-row class="flex-column ml-5 mt-5">
          <p class="text-caption mb-0">Nombre:</p>
          <p class="text-subtitle-2">{{ getNombre(administrador) }}</p>
        </v-row>
        <v-row class="flex-column ml-5">
          <p class="text-caption mb-0">Correo Electrónico:</p>
          <p class="text-subtitle-2">{{ administrador.email }}</p>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex align-end pb-4">
        <v-btn @click="cerrar" color="secondary" outlined class="mx-3"
          >Cancelar</v-btn
        >
        <v-btn
          v-if="verificarAdmin"
          color="secondary"
          class="white--text"
          @click="guardarAdministrador()"
          >Seleccionar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapActions, mapState } from "vuex";

  export default {
    name: "AgregarAdmonContratoComponent",
    props: {
      abrirDialogo: {
        type: Boolean,
        Default: false,
      },
    },

    data: () => ({
      btnVerificar: true,
      email: null,
      verificarAdmin: false,
      bloquearCampoEmail: false,
      administrador: {},
    }),
    computed: {
      ...mapState("solicitudCompra", [""]),
      ...mapState("agregarInsumo", ["id_solicitud"]),
    },
    methods: {
      ...mapActions("solicitudCompra", [
        "postAdministradoresContrato",
        "verificarEmpleado",
      ]),
      async guardarAdministrador() {
        let params = {
          id_solicitud_compra: this.id_solicitud,
          data: {
            email: this.administrador.email,
          },
        };

        await this.postAdministradoresContrato(params);
          
          this.$emit("reload");
          this.cerrar();

        
      },
      async verificarEmail() {
        let params = {
          email: this.email,
          id_solicitud_compra: this.id_solicitud,
        };

        const response = await this.verificarEmpleado(params);
        if (response.status === 200) {
          this.administrador = response.data;
          this.verificarAdmin = true;
          this.btnVerificar = false;
          this.bloquearCampoEmail = true;
        } else {
          this.temporalAlert({
            show: true,
            message: response.data,
            type: "error",
          });
        }

      },
      getNombre(item = null) {
        let nombre;
        if (item !== null) {
          nombre = item.primer_nombre + " ";
          if (item.segundo_nombre !== null) {
            nombre += item.segundo_nombre + " ";
          }
          if (item.tercer_nombre !== null) {
            nombre += item.tercer_nombre + " ";
          }
          if (item.primer_apellido !== null) {
            nombre += item.primer_apellido + " ";
          }
          if (item.segundo_apellido !== null) {
            nombre += item.segundo_apellido + " ";
          }
          if (item.apellido_casada !== null) {
            nombre += item.apellido_casada;
          }
        }
        return nombre;
      },
      cerrar() {
        this.email = null;
        this.verificarAdmin = false;
        this.btnVerificar = true;
        this.bloquearCampoEmail = false;
        this.$emit("cerrar");
      },
    },
  };
</script>
