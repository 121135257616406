<template>
  <section class="mx-sm-4">
    <v-btn
      v-if="!haveRoles(['ROLE_OFICIAL_CUMPLIMIENTO', 'ROLE_AUDITOR_INTERNO_AUIN'])"
      class="secondary my-5"
      dark
      small
      @click="agregarAdmon = true"
    >
      Agregar administrador
    </v-btn>
    <p class="mb-5">Listado de administradores</p>
    <data-table-component
      :headers="headers"
      :items="lista_administradores_de_contrato"
      no-data-text="No hay datos para mostrar"
      class="mb-5"
      @paginar="paginar"
      v-models:pagina="paginacion.pagina"
      v-models:select="paginacion.registrosPorPagina"
      :total_registros="registrosTotales"
    >
      <template v-slot:[`item.correlativo`]="{ item }">
        {{ item.correlativo }}
      </template>
      <template v-slot:[`item.nombres`]="{ item }">
        {{ getNombres(item.Empleado.Persona) }}
      </template>
      <template v-slot:[`item.apellidos`]="{ item }">
        {{ getApellidos(item.Empleado.Persona) }}
      </template>
      <template v-slot:[`item.dui`]="{ item }">
        {{ item.Empleado.Persona?.DocumentoPersonas?.numero_documento }}
      </template>
      <template v-slot:[`item.email`]="{ item }">
        {{ item.Empleado.Usuario?.email }}
      </template>
      <template v-slot:[`item.accion`]="{ item }">
        <v-tooltip right v-if="!haveRoles(['ROLE_OFICIAL_CUMPLIMIENTO', 'ROLE_AUDITOR_INTERNO_AUIN'])">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              @click="abiriDialogoConfirmacion(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>Eliminar</span>
        </v-tooltip>
      </template>
    </data-table-component>
    <agregar-admon-contrato
      :abrirDialogo="agregarAdmon"
      @cerrar="agregarAdmon = false"
      @reload="obtenerAdministradores"
    />
    <modal-confirmar
      :show="dialogoConfirmacion"
      btnConfirmar="Aceptar"
      title="¿Deseas remover al administrador de contratos?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="cerrarDialogoConfirmacion"
      @confirm="aceptarEliminacion"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AgregarAdmonContrato from "../views/JefeAreaAprobacion/components/AgregarAdmonContratoComponent.vue";
import modalConfirmar from "./ConfirmationDialogComponent.vue";
import DataTableComponent from "./DataTableComponent.vue";

export default {
  name: "AdministradorContratoComponent",
  components: {
    AgregarAdmonContrato,
    modalConfirmar,
    DataTableComponent
},
  data() {
    return {
      dialogoConfirmacion: false,
      agregarAdmon: false,
      administradorSeleccionado: {},
      headers: [
        { text: "Nombre", align: "center", sortable: false, value: "nombres" },
        { text: "Apellidos", align: "center", sortable: false, value: "apellidos" },
        { text: "DUI", align: "center", sortable: false, sortable: false, value: "dui" },
        { text: "Correo Electrónico", align: "center", sortable: false, value: "email" },
        { text: "Acciones", align: "center", sortable: false, value: "accion" },
      ],
      // Paginacion
      paginacion: {
        pagina: 1,
        registrosPorPagina: 10,
      },
      registrosTotales: 0,
    };
  },
  computed: {
    ...mapState("solicitudCompra", ["lista_administradores_de_contrato"]),
    ...mapState("agregarInsumo", ["id_solicitud"]),
    ...mapState("SolicitudCompraStore/ProcesoTradicionalStore", ["solicitud"]),
    ...mapState(["selectedUnidad", "cargos"]),
    habilitarAggAdmon() {
      return this.solicitud?.paso?.id == 2;
    },
  },
  methods: {
    ...mapActions("solicitudCompra", [
      "getAdministradoresContrato",
      "postAdministradoresContrato",
      "deleteAdministradoresContrato",
      "verificarEmpleado",
    ]),
    getNombres(item) {
      let nombres;
      if (item.primer_nombre !== null) {
        nombres = item.primer_nombre + " ";
      }
      if (item.segundo_nombre !== null) {
        nombres += item.segundo_nombre + " ";
      }
      if (item.tercer_nombre !== null) {
        nombres += item.tercer_nombre + " ";
      }
      return nombres;
    },
    getApellidos(item) {
      let apellidos;
      if (item.primer_apellido !== null) {
        apellidos = item.primer_apellido + " ";
      }
      if (item.segundo_apellido !== null) {
        apellidos += item.segundo_apellido + " ";
      }
      if (item.apellido_casada !== null) {
        apellidos += item.apellido_casada + " ";
      }
      return apellidos;
    },
    abiriDialogoConfirmacion(item) {
      this.administradorSeleccionado = item;
      this.dialogoConfirmacion = true;
    },
    cerrarDialogoConfirmacion() {
      this.dialogoConfirmacion = false;
    },
    async aceptarEliminacion() {
      let params = {
        id_solicitud_compra: this.id_solicitud,
        data: {
          id_empleado_solicitud: this.administradorSeleccionado.id,
        },
      };
      const response = await this.deleteAdministradoresContrato(params);
      if (response.status === 200) {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "success",
        });

        const paginasTotales = Math.ceil(this.registrosTotales / this.paginacion.registrosPorPagina);

        if (
          this.paginacion.pagina === paginasTotales &&
          this.paginacion.pagina !== 1 &&
          this.lista_administradores_de_contrato.length === 1
        ) {
          this.paginacion.pagina = this.paginacion.pagina - 1;
        } else {
          this.obtenerAdministradores();
        }

      } else {
        this.temporalAlert({
          show: true,
          message: response.data,
          type: "error",
        });
      }
      this.dialogoConfirmacion = false;
    },
    async obtenerAdministradores() {

      if (this.id_solicitud === null) return;
      let params = {
        id_solicitud_compra: this.id_solicitud,
        filters: {
          pagination: true,
          page: this.paginacion.pagina,
          per_page: this.paginacion.registrosPorPagina,
        },
      };

      const { headers } = await this.getAdministradoresContrato(params);
      this.registrosTotales = Number(headers.total_rows || 0);
    },
    paginar(paginacion) {
      this.paginacion = {
        pagina: paginacion.pagina,
        registrosPorPagina: paginacion.cantidad_por_pagina,
      };
    },
  },
  watch: {
    paginacion: {
      handler() {
        this.obtenerAdministradores();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
