<template>
  <div class="d-flex flex-column">
    <v-row class="my-4" align="center">
      <v-col cols="12" sm="12" md="4">
        <v-btn
          :loading="loading_aprobacion"
          @click="saveChangesUFI()"
          :disabled="calculoErrors || (!aprobadosErrors && !permitirGuardar) || habilitarAcciones"
          color="secondary"
        >
          <v-icon color="white">mdi-content-save</v-icon>
          Guardar cambios
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="8">
        <div class="text-end mb-6">
          <v-btn
            @click="modalCancelarInsumo = true"
            class="px-0 mx-5"
            text
            :disabled="habilitarAcciones"
            color="red"
          >
            <v-icon small class="mr-2" color="red">mdi-cancel</v-icon>
            Descartar insumo
          </v-btn>
          <v-btn
            @click="modalObservacionInsumo = true"
            class="px-0 mx-5"
            text
            :disabled="habilitarAcciones"
            color="primary"
          >
            <v-icon small class="mr-2" color="primary"
              >mdi-message-reply-text</v-icon
            >
            Marcar con observación
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      :headers="filteredHeaders"
      :items="insumosDetalle"
      class="elevation-0 bgMinsal table-insumos"
      hide-default-footer
      :items-per-page="-1"
      item-key="id"
      no-data-text="No hay productos"
      :mobile-breakpoint="875"
    >
      <template #[`item.correlativo`]="{ item }">
        {{ insumosDetalle.map((col) => col.id).indexOf(item.id) + 1 }}
      </template>
      <template #[`item.precio_unitario`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.precio_unitario)
        }}
      </template>
      <template #[`item.total`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.total)
        }}
      </template>
      <template v-slot:[`item.url_origen_compra_en_linea`]="{ item }">
        <a
          class="secondary--text"
          :href="item.url_origen_compra_en_linea"
          target="blank_"
          v-text="'Ver artículo'"
        />
      </template>
      <template #[`item.cantidad_aprobar`]="{ item }">
        <v-col class="d-flex justify-center">
          <v-col cols="12">
            <v-text-field
              v-if="!item.aprobacion_ufi"
              v-model="item.cantidad_aprobar"
              outlined
              dense
              type="number"
              class="mb-n6"
              @change="calcularFondos(item)"
              min="0"
              :rules="[validateValue]"
            />
            <span v-else> {{ item.cantidad_aprobar }} </span>
          </v-col>
        </v-col>
      </template>
      <template #[`item.total_aprobar`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item?.precio_unitario * item?.cantidad_aprobar)
        }}
      </template>
      <template #[`item.aprobacion_ufi`]="{ item }">
        <v-icon v-if="item.aprobacion_ufi" color="green"
          >mdi-check-circle-outline</v-icon
        >
        <h4 v-else>Sin aprobación</h4>
      </template>
      <template #[`item.aprobar`]="{ item }">
        <v-col class="d-flex justify-center">
          <v-checkbox
            :disabled="
              item.cantidad_aprobar <= 0 ||
              /^\d+$/.test(item.cantidad_aprobar) == false ||
              item.cantidad_aprobar == '' ||
              habilitarCheck(item)
            "
            @change="calcularFondos(item)"
            v-model="item.aprobacion_ufi"
          ></v-checkbox>
        </v-col>
      </template>
    </v-data-table>
    <ConfirmationDialog
      :show="modalCancelarInsumo"
      title="¿Deseas descartar el insumo?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      @close="modalCancelarInsumo = false"
      @confirm="changeStatusInsumo('descartado')"
    />
    <ConfirmationDialog
      :show="modalObservacionInsumo"
      title="Marcar con observación"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      btnConfirmar="Continuar"
      showMotivoCancelar
      @input="setMotivo"
      @close="modalObservacionInsumo = false"
      @confirm="changeStatusInsumo('observar')"
    />
  </div>
</template>
<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "InsumoDetalleTableComponent",
  components: {
    ConfirmationDialog,
  },
  props: {
    insumo: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    disponible: 0,
    fondos_necesarios: 0,
    saldo: 0,
    aprobadosErrors: false,
    loading_aprobacion: false,
    headers: [],
    modalCancelarInsumo: false,
    modalObservacionInsumo: false,
    motivoObservacion: "",
  }),
  computed: {
    ...mapState(["cargos", "selectedUnidad"]),
    insumosDetalle() {
      return this.insumo.solicitud_compra_detalle_obs.map((col) => {
        const array = {
          mercancia:
            this.insumo.presentacion ||
            this.insumo.nombre_origen_compra_en_linea,
          mes: col.mes,
          id_mes: col.id_mes,
          cantidad: col.cantidad,
          precio_unitario: this.insumo.precio_unitario,
          total: col.total,
          aprobacion_ufi: col.aprobacion_ufi,
          id: col.id,
          cantidad_aprobar: col.cantidad_aprobar,
          url_origen_compra_en_linea: this.insumo.url_origen_compra_en_linea,
        };

        if (this.$route.params.tipo == 2) {
          array.unidad_medida = this.insumo.unidad_medida_proveedor;
        } else {
          array.unidad_medida = this.insumo.unidad_medida;
        }

        return array;
      });
    },
    verifyAllItems() {
      return this.insumosDetalle.every((col) => col.aprobacion_ufi);
    },
    calculoErrors() {
      return (
        this.fondos_necesarios > this.insumo.obs_linea_unidad?.monto_disponible
      );
    },
    permitirGuardar() {
      return (
        this.insumosDetalle.filter((target) => isNaN(target.cantidad_aprobar))
          .length == 0
      );
    },
    filteredHeaders() {
      if (this.$route.params.tipo !== 3) {
        return this.headers.filter(
          (target) => target.value !== "url_origen_compra_en_linea"
        );
      } else return this.headers;
    },
    cargoSeleccionado() {
      return this.selectedUnidad.cargo[0].id;
    },
    habilitarAcciones() {
      return (
        this.cargoSeleccionado == this.cargos.jefe_ufi &&
        (this.insumo.id_estado == 5 || this.insumo.id_estado == 7)
      );
    },
  },
  methods: {
    ...mapActions("agregarInsumo", ["getInsumosList"]),

    validateValue(value) {
      const regex = /^\d+$/;
      if (!regex.test(value)) {
        return "Ingrese una cantidad válida";
      } else return true;
    },
    habilitarCheck(v) {
      return v.cantidad_aprobar > v.cantidad;
    },
    fillHeaders() {
      this.headers = [
        {
          text: "Correlativo",
          value: "correlativo",
          align: "center",
          sortable: false,
        },
        {
          text: "Mercancía",
          value: "mercancia",
          align: this.$route.params.tipo != 1 ? "center" : " d-none",
          sortable: false,
        },
        {
          text: "Unidad de medida",
          align: "center",
          value: "unidad_medida",
          sortable: false,
        },
        // {
        //   text: "Mes",
        //   align: "center",
        //   value: "mes",
        //   sortable: false,
        // },
        {
          text: "Cantidad",
          align: "center",
          value: "cantidad",
          sortable: false,
        },
        {
          text: "Precio unitario ($)",
          align: "center",
          value: "precio_unitario",
          sortable: false,
        },
        {
          text: "Total ($)",
          align: "center",
          value: "total",
          sortable: false,
        },

        {
          text: "Cantidad aprobar",
          value: "cantidad_aprobar",
          align: this.verifyAllItems ? "center" : "start",
          sortable: false,
          width: "200",
        },
        {
          text: "Total ($)",
          align: "center",
          value: "total_aprobar",
          sortable: false,
        },
        {
          text: "Enlace de compra",
          align: "center",
          value: "url_origen_compra_en_linea",
        },
        {
          text: "Estado",
          align: "center",
          sortable: false,
          value: "aprobacion_ufi",
        },
        {
          text: "Aprobar",
          align: "center",
          value: "aprobar",
          sortable: false,
        },
      ];
    },
    calcularFondos(item) {
      let regex_cantidad_aprobar = /^\d+$/;
      if (
        item.cantidad_aprobar > item.cantidad &&
        regex_cantidad_aprobar.test(item.cantidad_aprobar) == true
      ) {
        if (regex_cantidad_aprobar.test(item.cantidad_aprobar) == true) {
          this.temporalAlert({
            show: true,
            message: "La cantidad a aprobar debe de ser un número entero",
            type: "warning",
          });
        }
        this.temporalAlert({
          show: true,
          message:
            "La cantidad a aprobar no puede ser mayor que la cantidad solicitada",
          type: "warning",
        });
      } else {
        let cantidad_precio = 0;
        for (let prop of this.insumosDetalle) {
          if (prop.cantidad_aprobar !== null && prop.aprobacion_ufi) {
            cantidad_precio += prop.precio_unitario * prop.cantidad_aprobar;
          }
        }
        if (isNaN(cantidad_precio)) {
          this.temporalAlert({
            show: true,
            message:
              "Ingrese una cantidad mayor a cero para aprobar la solicitud",
            type: "warning",
          });
          return;
        } else {
          this.aprobadosErrors =
            this.insumosDetalle.filter((target) => target.aprobacion_ufi)
              .length > 0;
          this.fondos_necesarios = cantidad_precio;
          this.saldo =
            this.insumo.financiamiento_unidad?.monto_disponible -
            this.fondos_necesarios;
        }
      }
    },
    async saveChangesUFI() {
      try {
        let body = [];
        this.loading_aprobacion = true;

        for (let prop of this.insumosDetalle) {
          body.push({
            id: prop.id,
            id_mes: prop.id_mes,
            cantidad: prop.cantidad,
            aprobacion_ufi: prop.aprobacion_ufi,
            cantidad_aprobar: prop.cantidad_aprobar,
          });
        }

        await this.services.SolicitudCompra.putSolicitudesInsumosDetalleUFI({
          insumos_aprobados: body,
        });
        this.loading_aprobacion = false;
        this.$emit("reloadInfo");
      } catch (error) {
        setTimeout(() => {
          this.loading_aprobacion = false;
        }, 2000);
      }
    },
    setMotivo(value) {
      this.motivoObservacion = value;
    },
    async changeStatusInsumo(action) {
      let id_estado = action == "descartado" ? 7 : 5;
      let body = {
        id_estado_solicitud: id_estado,
        observacion: this.motivoObservacion,
      };
      const { status } =
        await this.services.SolicitudCompra.putSolicitudesInsumosEstado(
          this.$route.params.id_solicitud,
          this.insumo.id,
          body
        );
      if (status == 200) {
        await this.getInsumosList(this.$route.params.id_solicitud);
        this.temporalAlert({
          show: true,
          message: `Insumo ${
            action == "descartado" ? "descartado" : "observado"
          } con éxito`,
          type: "success",
        });
        this.modalObservacionInsumo = false;
        this.modalCancelarInsumo = false;
      }
    },
  },
  created() {
    this.aprobadosErrors =
      this.insumosDetalle.filter((target) => target.aprobacion_ufi).length > 0;
    this.insumosDetalle.forEach((item) => {
      if (item.aprobacion_ufi)
        this.fondos_necesarios += item.precio_unitario * item.cantidad_aprobar;
    });
    this.disponible = this.insumo.financiamiento_unidad?.monto_disponible;
    this.saldo = this.disponible - this.fondos_necesarios;
  },
  mounted() {
    this.fillHeaders();
  },
};
</script>
<style lang="scss">
.table-insumos table {
  tbody tr td:nth-child(8),
  thead tr th:nth-child(8) {
    text-align: center !important;
  }
}
</style>
