<template>
  <v-expansion-panels focusable accordion flat>
    <v-expansion-panel
      v-for="(insumo, i) in insumosList"
      :key="i"
      class="panel"
    >
      <v-expansion-panel-header class="panel">
        <span
          >{{ insumo.codigo_obs }} {{ insumo.obs || insumo.nombre_origen_compra_en_linea }}
          <v-icon v-if="checkApproved(insumo)" color="green"
            >mdi-check-circle-outline</v-icon
          ></span
        ></v-expansion-panel-header
      >
      <v-expansion-panel-content class="pa-5">
        <InsumoDetalleTableComponent
          @reloadInfo="getInsumosList(id_solicitud)"
          :insumo="insumo"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import InsumoDetalleTableComponent from "./InsumoDetalleTableComponent.vue";
export default {
  name: "ListadoInsumosAccordion",
  components: {
    InsumoDetalleTableComponent,
  },
  computed: {
    ...mapState("agregarInsumo", [
      "id_solicitud",
      "insumosList",
      "insumoFilters",
      "fondos",
    ]),
  },
  methods: {
    ...mapActions("agregarInsumo", ["getInsumosList", "getFondosAsignados"]),
    checkApproved(insumo) {
      return (
        insumo.solicitud_compra_detalle_obs.filter(
          (target) => target.aprobacion_ufi
        ).length > 0
      );
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-expansion-panel--active > .v-expansion-panel-header) {
  background: #111c4e !important;
  color: white !important;
}
:deep(.v-expansion-panel--active .v-expansion-panel-header__icon i) {
  color: white !important;
}
</style>
